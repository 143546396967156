<template>
    <div>
        <b-card title="" no-body>
            <b-card-body>
                <div class="d-flex justify-content-between flex-wrap mb-2">
                    <div>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
                            class="mr-1 float-left" @click="onCreate()">
                            <feather-icon icon="DatabaseIcon" class="mr-50" />
                            <span class="align-middle">Tambah</span>
                        </b-button>
                    </div>
                </div>

                <b-row class="mb-0">
                    <b-col>
                        <b-form-group label-cols-sm="5" label-align-sm="left" label-size="md" label-for="filterInput">
                            <b-input-group size="md" class="input-group-merge">
                                <b-input-group-prepend is-text>
                                    <feather-icon icon="SearchIcon" />
                                </b-input-group-prepend>
                                <b-form-input placeholder="Cari" id="filterInput" v-model="filter.search"
                                    type="search" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>

            <b-table striped hover responsive class="position-relative" :per-page="perPage" :current-page="currentPage"
                :items="items" :fields="columnOptions" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                @filtered="onFiltered" ref="cTable" show-empty>
                <template #empty="" style="text-align: center"> Tidak ada data </template>

                <template #cell(id)="data">
                    <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
                    <b-form-checkbox @change="changeBox(data, $event)" :checked="ids.some((id) => id === data.item.id)">
                    </b-form-checkbox>
                </template>

                <template #cell(no)="data">
                    {{ (currentPage - 1) * perPage + (data.index + 1) }}
                </template>

                <template #cell(createdAt)="data">
                    {{ $dayjs(data.value).format('DD-MM-YYYY') }}
                </template>
                <template #cell()="data">
                    {{ data.value }}
                </template>

                <template #cell(action)="data" class="text-center">
                    <div style="min-width: 270px">
                        <!-- <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary"
                            v-b-popover.hover.bottom="'Detail'" size="sm" class="btn-icon float-sm-center mr-1"
                            @click="onShow(data)">
                            <feather-icon icon="EyeIcon" />
                        </b-button> -->

                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning"
                            v-b-popover.hover.bottom="'Ubah'" size="sm" class="btn-icon float-sm-center mr-1"
                            @click="onEdit(data.item.id)">
                            <feather-icon icon="EditIcon" />
                        </b-button>
                        
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger"
                            v-b-popover.hover.bottom="'Hapus'" size="sm" class="btn-icon float-sm-center mr-1"
                            @click="onDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                        </b-button>
                    </div>
                </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                <b-form-group label="Per Page" label-cols="6" label-align="left" label-size="md"
                    label-for="sortBySelect" class="text-nowrap mb-md-0 mr-1">
                    <b-form-select id="perPageSelect" v-model="onTablePerPage" @change="changePerPage" size="md" inline
                        :options="pageOptions" />
                </b-form-group>

                <div>
                    <b-pagination v-model="onTableCurrentPage" :total-rows="totalRows" :per-page="perPage" first-number
                        prev-class="prev-item" next-class="next-item" last-number class="mb-0">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </b-card-body>
        </b-card>

        <b-modal size="xl" :title="`Form ${config.name} kritik & saran`" ref="form" ok-only
            ok-variant="outline-secondary" ok-title="Kembali">
            <validation-observer ref="formStudent">
                <b-form>
                    <b-form-group label="Perihal" label-for="h-place" label-cols-md="2">
                        <validation-provider #default="{ errors }" name="perihal" rules="required">
                            <b-form-input :disabled="config.action == 'show'" id="h-place" placeholder="Perihal"
                                :state="errors.length > 0 ? false : null" v-model="model.name" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                </b-form>
                <b-button v-if="config.action != 'show'" v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                    variant="primary" class="float-right" @click.prevent="handleSubmit()">
                    Simpan
                </b-button>
            </validation-observer>
        </b-modal>
    </div>
</template>
  
<script>
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BCardBody,
    VBPopover,
    VBModal,
    BModal,
    BAlert,
    BFormFile,
    BRow,
    BCol,
    BForm
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FileDownload from "js-file-download";
import vSelect from "vue-select";

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroupPrepend,
        BButton,
        BCardBody,
        BCard,
        Ripple,
        VBPopover,
        VBModal,
        BModal,
        BAlert,
        BFormFile,
        ToastificationContent,
        BRow,
        BCol,
        vSelect,
        BForm
    },
    directives: {
        Ripple,
        "b-popover": VBPopover,
        "b-modal": VBModal,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Function,
            required: true,
        },
        columns: {
            type: Array,
            required: true,
            default: () => [],
        },
        totalRows: {
            type: Number,
            required: true,
            default: () => 0,
        },
        currentPage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        perPage: {
            type: Number,
            required: true,
            default: () => 0,
        },
        api: {
            type: String,
            required: true,
        },
        add: {
            type: Boolean,
        },
        import: {
            type: Boolean,
        },
        export: {
            type: Boolean,
        },
        exportFile: {
            type: String,
        },
        filterCourse: {
            type: Boolean,
        },
    },
    data() {
        return {
            pageOptions: [10, 25, 50],
            onTablePerPage: this.$props.perPage,
            onTableCurrentPage: this.$props.currentPage,
            config: {
                action: 'create',
                name: 'tambah'
            },
            model: {
                id: null,
                name: null
            },
            sortBy: "createdAt",
            sortDesc: true,
            sortDirection: "desc",
            filter: {
                search: null,
            },
            filterOn: [],
            infoModal: {
                id: "info-modal",
                title: "",
                content: "",
            },
            actions: {
                add: Boolean(this.$props.add),
                import: Boolean(this.$props.import),
                export: Boolean(this.$props.export),
                filter: {
                    course: Boolean(this.$props.filterCourse),
                },
                show: false,
                edit: false,
                destory: false,
            },
            file: null,
            fileState: null,
            courses: [],
            ids: [],
        };
    },
    computed: {
        sortOptions() {
            return this.columns
                .filter((f) => f.sortable)
                .map((f) => ({ text: f.label, value: f.key }));
        },
        columnOptions() {
            return this.columns.map((column) => {
                if (column.actions) {
                    this.actions = { ...this.actions, ...column.actions };
                    delete column.actions;
                }

                return column;
            });
        },
    },
    created() {
        const _ = this;
    },
    mounted() {
    },
    methods: {
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`;
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // console.log('s');
            // this.totalRows = filteredItems.length;
            // this.currentPage = 1;
        },
        changePerPage() {
            this.$emit("changePerPage", this.onTablePerPage);
        },
        onShow(data) {
            const _ = this;
            _.$refs["form"].show();
            _.config.action = 'show';
            _.$axios
                .get(`input-target/${data.item.id}`)
                .then((res) => {
                    _.model.id = res.data.data.id;
                    _.model.name = res.data.data.name;
                });
        },
        onEdit(id) {
            const _ = this;
            _.$refs["form"].show();
            _.config.action = 'edit';
            _.$axios
                .get(`input-target/${id}`)
                .then((res) => {
                    _.model.id = res.data.data.id;
                    _.model.name = res.data.data.name;
                });
        },
        onCreate() {
            const _ = this;
            _.$refs["form"].show();
            _.config.action = 'create';
        },
        onDelete(id) {
            let _ = this;
            this.$swal({
                title: "Apakah Anda Yakin",
                text: "untuk menghapus data?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Ya",
                cancelButtonText: "Batal",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            })
                .then((resp) => {
                    if (resp.dismiss !== "cancel") {
                        _.$axios.delete(`${_.$props.api}/${id}`).then((res) => {
                            _.toastNotification(
                                "success",
                                "BellIcon",
                                "Berhasil menghapus data"
                            );
                            _.$refs.cTable.refresh();
                        });
                    }
                })
                .catch((err) => { });
        },
        handleOkImport(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.handleSubmitImport();
        },
        handleShowImport() {
            this.file = null;
            this.fileState = null;
        },
        checkFormValidityImport() {
            const valid = this.$refs.form.checkValidity();
            this.fileState = valid;
            return valid;
        },
        handleSubmitImport() {
            const _ = this;
            if (!_.checkFormValidityImport()) {
                return;
            }
            const formData = new FormData();
            formData.append("file", _.file, _.file.name);
            _.$axios.post(`${_.$props.api}/import`, formData, {}).then((res) => {
                const consume = res.data;
                if (consume.statusCode === 201) {
                    _.toastNotification(
                        "success",
                        "BellIcon",
                        `Berhasil menambahkan ${consume.data.length} data mahasiswa.`
                    );
                    _.$refs.cTable.refresh();
                }
            });
            _.$nextTick(() => {
                _.$bvModal.hide("modal-import");
            });
        },
        handleExport() {
            const _ = this;
            _.$axios
                .get(`${_.$props.api}/export`, {
                    responseType: "blob",
                })
                .then((res) => {
                    FileDownload(res.data, this.$props.exportFile);
                });
        },
        toastNotification(variant, icon, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Pemberitahuan",
                    icon,
                    text,
                    variant,
                },
            });
        },
        changeBox(row, event) {
            const _ = this;

            if (event) {
                _.ids.push(row.item.id);
            } else {
                const index = _.ids.indexOf(row.item.id);
                if (index > -1) {
                    _.ids.splice(index, row.item.id);
                }
            }
        },
        handleSubmit() {
            const _ = this;
            if (_.config.action == 'create') {
                _.$axios.post('input-target', _.model)
                    .then((res) => {
                        _.toastNotification('success', 'bellIcon', 'Data Berhasil Disimpan!')
                        _.$refs.cTable.refresh();
                    })
                    .catch((err) => {
                        _.toastNotification('danger', 'bellIcon', err.response.data.message)
                    });
            } else if (_.config.action == 'edit') {
                _.$axios.put(`input-target/${_.model.id}`, _.model)
                    .then((res) => {
                        _.toastNotification('success', 'bellIcon', 'Data Berhasil Diubah!')
                        _.$refs.cTable.refresh();
                    })
                    .catch((err) => {
                        _.toastNotification('danger', 'bellIcon', err.response.data.message)
                    });
            }
        },
    },
    watch: {
        onTableCurrentPage: function (newValue) {
            let _ = this;
            _.$emit("changeCurrentPage", newValue);
        },
        'config.action': function (newValue, oldValue) {
            if (newValue == 'create') {
                this.config.name = 'tambah'
            } else if (newValue == 'edit') {
                this.config.name = "ubah"
            } else {
                this.config.name = 'detail'
            }
        }
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
  