<template>
    <div>
        <table-crud :title="title" :items="getItems" :columns="columns" :totalRows="totalRows" :perPage="perPage"
            :currentPage="currentPage" :api="api" @changeCurrentPage="changeCurrentPage" @changePerPage="changePerPage">
        </table-crud>
    </div>
</template>

<script>
import {
    BCard,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import TableCrud from "@/components/TableCrud.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BCard,
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        TableCrud,
        ToastificationContent,
    },
    data() {
        return {
            title: "Kritik & Saran",
            api: "input-target",
            dir: false,
            items: [],
            totalRows: 0,
            perPage: 10,
            limit: 0,
            orderBy: "createdAt",
            sortBy: "desc",
            currentPage: 1,
            search: "",
            columns: [
                { key: "no", label: "#", sortable: false },
                { key: "name", label: "Ditujukan", sortable: true },
                { key: "createdAt", label: "tanggal", sortable: true },
                {
                    key: "action",
                    label: "aksi",
                    class: "text-center",
                    actions: { show: true },
                },
            ],
        };
    },
    mounted() {
        const _ = this;
        if (_.$route.params) {
            if (_.$route.params.variant) {
                _.toastNotification(
                    _.$route.params.variant,
                    "BellIcon",
                    _.$route.params.text
                );
            }
        }
    },
    methods: {
        getItems(ctx, callback) {
            table.getItems(ctx, callback, this);
        },
        changeCurrentPage(page) {
            table.changeCurrentPage(this, page);
        },
        changePerPage(perPage) {
            table.changePerPage(this, perPage);
        },
        toastNotification(variant, icon, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Pemberitahuan",
                    icon,
                    text,
                    variant,
                },
            });
        },
    },
};
</script>